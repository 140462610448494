export default {
    namespaced: true,
  
    state: {
      email: null,
      token: null,
      loggedUser: null,
    },
    mutations: {
      SET_TOKEN ( state, token ) {
        state.token = token;
      },
      CLEAR_TOKEN ( state ) {
        state.token = null;
      },
      SET_EMAIL ( state, email ) {
        state.email = email;
      },
      CLEAR_EMAIL ( state ) {
        state.email = null;
      },
      SET_LOGGED_USER ( state, user ) {
        state.loggedUser = user;
      },
      CLEAR_LOGGED_USER ( state ) {
        state.loggedUser = null;
      }
    },
  
    getters: {
      isAuthenticated ( state ) {
        let token = state.token ? state.token : localStorage.getItem( "token" );
        return token != null;
      },
  
      email ( state ) {
        if ( state.email ) {
          return state.email;
        } else {
          let user = localStorage.getItem( 'userCredentials' );
          let userCredentials = JSON.parse( user );
          return userCredentials.email;
        }
      },
      token: ( state ) => state.token,
      loggedUser ( state ) {
        if ( state.loggedUser ) {
          return state.loggedUser;
        } else {
          let user = localStorage.getItem( 'userCredentials' );
          let userCredentials = JSON.parse( user );
          return userCredentials.email;
        }
      },
    },
    actions: {
      logUserCredentials ( vuexContext, param ) {
        localStorage.setItem( "loggedUser", JSON.stringify( param.currUser ) );
        vuexContext.commit( 'SET_LOGGED_USER', param.currUser );
      },
    }
  };
  