import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/config/firebase';

const AuthLayout = () => import('@/layout/AuthLayout');
const DashboardLayout = () => import('@/layout/DashboardLayout');
const Login = () => import('@/views/Login');
const ResetPassword = () => import('@/views/account/ResetPassword');
const Users = () => import('@/views/Users');
const Contacts = () => import('@/views/Contacts');
const Inquiries = () => import('@/views/Inquiries');

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: '/login',
      redirect: 'login',
      component: AuthLayout,
      meta: {
        isAuthPage: true,
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login,
        }
      ]
    },
    {
      path: '/reset-password',
      name: 'reset password',
      component: ResetPassword
    },
    {
      path: '/',
      redirect: 'users',
      component: DashboardLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/users',
          name: 'users',
          component: Users
        },
        {
          path: '/contacts',
          name: 'contacts',
          component: Contacts
        },
        {
          path: '/inquiries',
          name: 'inquiries',
          component: Inquiries
        }
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const isAuthPage = to.matched.some((x) => x.meta.isAuthPage);
  const currentUser = auth.currentUser;
  const allowedUser = "support@tawitech.ph";

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (currentUser) {
    if (isAuthPage && currentUser.email === allowedUser) {
      next("/");
    } else if (isAuthPage) {
      next("/contacts");
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;

