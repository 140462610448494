export default {
    state: {
        currSalesContact: {},
    },
    getters: {
        salesContacts: (state) => state.salesContacts,
        currSalesContact: (state) => state.currSalesContact,
    },
    mutations: {
        SET_CURR_SALES_CONTACT(state, salesContact) {
            state.currSalesContact = salesContact;
        },
        SET_ALL_SALES_CONTACTS(state, salesContacts) {
            state.salesContacts = salesContacts;
        },
    },
};
