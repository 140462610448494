<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    // Prevents history traversal from Login page to other pages
    window.onpopstate = () => {
      if (this.$route.path === '/login') {
        window.history.replaceState({ path: '/login' }, '', '/login');
      }
    };
  },
}
</script>

<style>
.help-block {
  color: #f44335;
  font-size: 12px;
  margin-bottom: 20px !important;
}

.tawitech-gray {
  background-color: #343434 !important;
  border-color: #343434 !important;
  color: white !important;
}

.tawitech-red {
  background-color: #E20818 !important;
  border-color: #E20818 !important;
  color: white !important;
}

.tawitech-secondary {
  background-color: #E20818 !important;
  border-color: #E20818 !important;
  color: #E20818 !important;
}

.icon-color {
  color: #E20818;
}

.status-pending {
  background-color: #D2D2D3 !important;
  color: #343434 !important;
  padding: 5px 10px 5px 10px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;

}

.status-viewed {
  background-color: #F7C1C5 !important;
  color: #E20818 !important;
  padding: 5px 10px 5px 10px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #E20818 !important;
  font-weight: 700;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #E20818 !important;
  background-color: #E20818 !important;
}

.page-item.active .page-link {
  background-color: #E20818 !important;
  border-color: #E20818 !important;
}

.table thead th {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.table td {
  font-size: 16px !important;
  font-weight: 400 !important;
}
</style>
