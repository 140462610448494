import firebase from 'firebase'
// Required for side-effects
require("firebase/firestore");

import config from '@/config/env-constants';
import prodConfig from '@/config/prod.env';

firebase.initializeApp(getEnvParam(config.currEnv));

const auth = firebase.auth();
const currentUser = auth.currentUser;
const db = firebase.firestore();
const storage = firebase.storage();

export { firebase, auth, db, storage, currentUser }

function getEnvParam(env) {
    if (env === 'prod') {
        return prodConfig;
    }
}
