import Vue from 'vue';
import App from './App.vue';
import { auth } from "./config/firebase";
import router from './router';
import store from './store';
import ArgonDashboard from "./plugins/argon-dashboard";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import Toaster from 'v-toaster';
import DateUtil from './utils/dateUtil';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'v-toaster/dist/v-toaster.css';

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});
Vue.use(Toaster, {
  timeout: 5000
});

export const EventBus = new Vue();

Vue.filter("dateAndTimeFormatter", (str) => {
  if (!str || isNaN(str)) return "-";
  return DateUtil.getFormattedDateWithTime(str);
});

auth.onAuthStateChanged((user) => {
  if (user) {
    auth.currentUser.updateProfile({
      displayName: Object.assign(JSON.parse(localStorage.getItem('userCredentials'))).email,
    }).then(() => store.commit("user/SET_USER_DETAILS", user));
  }

  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
